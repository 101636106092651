/* general styles */
.cart_item_alert {
  color: red;
  font-weight: normal;
}

/* cart styles */
.cart_item_content {
  margin-top: 0.3em;
  margin-bottom: 0.3em;
  padding: 0;
  text-align: right;

  input[type="text"] {
    display: inline-block;
    width: unset;
    margin-right: 0.25em;
  }
}

.discount_net,
.discount_vat,
.discount_total,
.cart_item_discount {
  color: red;
}

// .icon-arrows-ccw:before { content: '\e800'; } /* '' */


.cart_item_preview_image {
  float: right;
  margin-left: 1em;
}


/* cart summary styles */
.summary_item {

  ul.cart_item_content {
    list-style-type: none !important;
    // text-align:right;
    margin: 0 !important;
  }

  ul.cart_item_content li {
    display: inline-block !important;

    // padding-left: 0.5em;
    span {
      display: inline-block;
    }
  }

  a:hover {
    border: none;
    text-decoration: none;
  }

}

#cart_summary .cart_sum_content.invisible-grid {
  width: 100%;
  // float: right;
  text-align: right;
  margin-top: 1em;

  td {
    padding-left: 0.25em;
  }

  td.summary_label {
    width: 95%;
  }

  td.summary_ammount {
    padding-left: 1em;
  }
}

.shipping_info {
  max-width: 400px;
  display: inline-block;
}

.cart_summary_buttons {
  text-align: right;

  .cart_back {
    float: left;
  }
}

ul#cart_items {
  list-style-type: none;
  padding: 0;

  .cart_item_preview_image {
    float: none;
    margin-left: 0;
    vertical-align: text-bottom;
  }

  .cart_item_preview_wrapper:hover {
    text-decoration: none;
    border: none;
  }
}

#cart_viewlet {
  float: right;
  position: relative;
}

#portlet-cart,
#cart_viewlet {
  .cart_item {
    padding-bottom: 0.25em;
    margin-bottom: 0.75em;
  }

  .cart_item_content {
    text-align: left;

    .cart_item_count {
      height: unset;
      padding: 0em 0.25em;
      text-align: right;
      width: 3rem;
    }
  }

  .cart_item_price_wrapper {
    text-align: right;
  }

  .cart_item_discount_wrapper {
    text-align: right;
  }

  .cart_viewlet_actions,
  .portletFooter {
    text-align: center;
  }

  #cart_viewlet_summary {
    display: block;
    padding: 0 0.5em;
  }

  #cart_viewlet_summary a {
    margin-top: 5px;
    float: right;
    position: relative;
    z-index: 10;
    top: 0px;
    right: 0px;
  }

  #cart_viewlet_details {
    display: none;
    position: absolute;
    right: 0;
    z-index: 1;
    background-color: #fff;
    padding: 1em;
    margin-top: 2em;
    border: 1px solid #e5e5e5;
    ;
    border-radius: 0.25em;
    min-width: 260px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  }

  .cart_viewlet_actions {
    text-align: center;
  }

}

.checkout_cart_overview .cart_item {
  margin-bottom: 1em;
}

/* cart summary styles */
.summary_item {
  padding-top: 1.2em;
  padding-bottom: 0.6em;
  border-bottom: #ccc 1px solid;

  ul.cart_item_content {
    list-style-type: none !important;
    text-align: right;
    margin: 0 !important;
  }

  ul.cart_item_content li {
    display: inline !important;
    padding-left: 0.5em;
  }

  .price {
    width: 7em;
    display: inline-block;
    text-align: right;
  }

  input.cart_item_count,
  .portletCart input.cart_item_count {
    margin-right: 0.1em;
  }

  span.cart_item_quantity_unit {
    margin-right: 0.5em;
  }

  a.update_cart_action,
  a.remove_from_cart_action {
    float: none;
  }
}

.summary_wrapper {
  text-align: right;
}

.cart_sum_content {
  float: right;
}

.cart_sum_content td {
  text-align: right;
  padding-left: 1em;
}

.cart_summary_buttons {
  text-align: right;
  padding-top: 1.2em;
}

.portletCart .lastItem {
  text-align: center;
}

img.cart_item_preview_image {
  float: right;
  margin-left: 0.5em;
}

